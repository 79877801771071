
/* .MuiBox-root{
    display: flex;
    align-items: flex-end;
    direction: row;
    padding: 0;
    margin: 0;
    
} */

.allAppts{
    display: flex;
    align-items: flex-end;
    direction: row;
    box-sizing: border-box;

}

.MuiTableFooter-root{
    
}
.MuiTablePagination-spacer{
    width: 350px;
} 
.MuiTableFooter-root{
    display: flex;
    align-items: flex-end;
    direction: row;
    /* background: #666; */
}

.MuiTableRow-root{
    padding:0;

}

.MuiIconButton-root{
    padding: 0;
    margin: 0;
    width: 80px;
}

.MuiTablePagination-displayedRows {
    margin-top:10px;
}
.MuiTablePagination-selectLabel{
    margin-top:10px;
}


.MuiInputBase-root{
    border: 1px solid #666;
    border-radius: 5px;
}

.ant-modal-close-x{
    display:none;

}