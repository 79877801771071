@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);

body{
  font-size: 16px;
  color: #333;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.home,
.reports,
.products {
  display: flex;
  height: 90vh;
  align-items: start;
  justify-content: center;
  font-size: 3rem;
  
}
.tableContainer{
  flex-direction: column;
  
}
.tableHeading{
  text-align: center;
  color: #060b26;
  margin-top: 40px;
}
.TableView{
  max-width: 650px;
 
}

.buttonText{
  max-width: 50px;
}

.buttonText span{
  margin-left: 0;
}
.ant-btn span{
  margin-left: 0;
}

.container{
  box-sizing:border-box; 
  font-size: 20px;
  margin-top: 20px;
  text-align: center;

}
.heading{
  text-align: center;
  color: #333;
}

.textarea{
 display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;

}
.textareaLabel{
  max-width: 160px;
  margin-right: 10px;
  display: block;
}
.textareaField{
  width: 80%;
  flex-direction: column;
}
.textarea{
  min-width: 83.4%;
}

.label,label{
  margin-right: 10px;
  width:160px;
  text-align: right;
  
}

 .form input {
  width: 60%;
  
}
.textarea{
  width: 60%;
}

.submitbtn{

  margin-left: 15%;
  border: 1px solid #060b26;
  border-radius: 5px;
  background-color:  #060b26;
  color: #f2f2f2;
  
}
.submitDiv{
  text-align: right;
  max-width: 80%;

}

.errors{
  color: red;
  text-align: center;
}

.containerTextarea {
  box-sizing: border-box;
}
/* 
input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
} */

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}
/* 
input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
} */

/* input[type=submit]:hover {
  background-color: #45a049;
} */

.containerText {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.col-25 {
  float: left;
  width: 27.6%;
  margin-top: 6px;
  text-align: right;

}

.col-75 {
  float: left;
  width: 72%;
  margin-top: 6px;
  
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.imagetag{
  text-align: center;
}
input[type=text],.textarea {
  padding-left: 5px;
}
input[type=text]{
  border: 1px solid #060b26;
  border-radius: 5px;

}
/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 1200px) {
  .col-25 {
    width: 29.2%;
  }
  .col-75 {
    width: 70%;
  }
}
@media screen and (max-width: 800px) {
  .col-25, .col-75, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
  .submitbtn{
    margin-left: 0;
    width: 40%;
  }
  .submitDiv{
    width: 100%;
    padding-left: 50px;
    text-align: right;
    right: 0;
  }
  
}
@media screen and (min-width: 800px) {
  .submitbtn{
    margin-left: 0;
    width: 40%;
  }
  .submitDiv{
    width: 100%;
    padding-left: 50px;
    text-align: right;
  }
 
}
@media screen and (max-width: 999px) {
  .col-25 {
    width: 32.5%;
  }
  .col-75 {
    width: 67%;
  }
}
@media screen and (max-width: 760px) {
  .col-25 {
    width: 36%;
  }
  .col-75 {
    width: 64%;
  }
}

@media screen and (max-width: 478px) {
  .col-25 {
    width: 40%;
  }
  .col-75 {
    width: 60%;
  }
  .submitDiv{
    width: 100%;
    margin-left: 20px;
    padding-left: 0;
  }
}
@media screen and (max-width: 478px) {
  input[type=submit],input[type=text],input[type=file],.textarea{
    width: 100%;
  }
  input[type=submit]{
    margin-top: 20px;

  }
  .col-25 {
    width:100%;
    text-align: center;
  }
  .col-75 {
    width: 100%;
  }
}

.statusText{
  font-size: 20px;
  color: #060b26;
}

.ant-modal-close-x{
  display: none;
}

.responsive_image{
  max-width: 300px;
  max-height: 300px;
  width: 40%;
  height:auto
}


.UpdateLink{
  background-color: transparent;
  color: transparent;
}

.UpdateIcon{
  color: aliceblue;
}





body {font-family: Arial, Helvetica, sans-serif;}
.form123 {border: 3px solid #f1f1f1;}

.password, .username {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover {
  opacity: 0.8;
}

.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

.imgcontainer123 {
  text-align: center;
  margin: 24px 0 12px 0;
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

.container123 {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }
}
.navbar {
    background-color: #060b26;
    height: 80px;
    display: flex;
    align-items: center;
    
    
  }

  .navIcon{
    justify-content: start;

    max-width: 450px;
  }

  .navbarText{
    justify-content: center;
    color: aliceblue;
  }
  .navbarText h5{
    color: beige;
    margin-top: 5px;
  }
  .MidDivSection{
    color:#f5f5f5;
    display: none;
    background-color:#060b26;
    text-align: center;
    min-height: 20px;
    margin: 0;
    padding: 0;
  }
  .MidDivSection h5{
    color:#f5f5f5;
  }
  @media only screen and (max-width: 900px) {
    .MidDivSection{
      display: block;
    }
    .navbarText{
      display: none;
    }
    .navbar {
      margin-top: -10px;
    }
    .gapPadding{
      padding-top: 25px;
      background-color:#060b26 ;
    }
  }
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: #060b26;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }
  

/* .MuiBox-root{
    display: flex;
    align-items: flex-end;
    direction: row;
    padding: 0;
    margin: 0;
    
} */

.allAppts{
    display: flex;
    align-items: flex-end;
    direction: row;
    box-sizing: border-box;

}

.MuiTableFooter-root{
    
}
.MuiTablePagination-spacer{
    width: 350px;
} 
.MuiTableFooter-root{
    display: flex;
    align-items: flex-end;
    direction: row;
    /* background: #666; */
}

.MuiTableRow-root{
    padding:0;

}

.MuiIconButton-root{
    padding: 0;
    margin: 0;
    width: 80px;
}

.MuiTablePagination-displayedRows {
    margin-top:10px;
}
.MuiTablePagination-selectLabel{
    margin-top:10px;
}


.MuiInputBase-root{
    border: 1px solid #666;
    border-radius: 5px;
}

.ant-modal-close-x{
    display:none;

}

/* .MuiBox-root{
    display: flex;
    align-items: flex-end;
    direction: row;
    padding: 0;
    margin: 0;
    
} */

.allAppts{
    display: flex;
    align-items: flex-end;
    direction: row;
    box-sizing: border-box;

}

.MuiTableFooter-root{
    
}
.MuiTablePagination-spacer{
    width: 350px;
} 
.MuiTableFooter-root{
    display: flex;
    align-items: flex-end;
    direction: row;
    /* background: #666; */
}

.MuiTableRow-root{
    padding:0;

}

.MuiIconButton-root{
    padding: 0;
    margin: 0;
    width: 80px;
}

.MuiTablePagination-displayedRows {
    margin-top:10px;
}
.MuiTablePagination-selectLabel{
    margin-top:10px;
}


.MuiInputBase-root{
    border: 1px solid #666;
    border-radius: 5px;
}

.ant-modal-close-x{
    display:none;

}
#d3demo{
	min-width: 80%;
	min-height: 80%;
}
