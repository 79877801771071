.navbar {
    background-color: #060b26;
    height: 80px;
    display: flex;
    align-items: center;
    
    
  }

  .navIcon{
    justify-content: start;

    max-width: 450px;
  }

  .navbarText{
    justify-content: center;
    color: aliceblue;
  }
  .navbarText h5{
    color: beige;
    margin-top: 5px;
  }
  .MidDivSection{
    color:#f5f5f5;
    display: none;
    background-color:#060b26;
    text-align: center;
    min-height: 20px;
    margin: 0;
    padding: 0;
  }
  .MidDivSection h5{
    color:#f5f5f5;
  }
  @media only screen and (max-width: 900px) {
    .MidDivSection{
      display: block;
    }
    .navbarText{
      display: none;
    }
    .navbar {
      margin-top: -10px;
    }
    .gapPadding{
      padding-top: 25px;
      background-color:#060b26 ;
    }
  }
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: #060b26;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }
  